<template>
  <v-simple-table class="fl-ratio-detail-table">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-center font-weight-bold text-heading-4 px-0">
            {{ $t("sales") }}
          </th>
          <th class="text-right font-weight-medium text-heading-4">
            {{ totalSale | toCurrency }}
          </th>
          <th class="text-center font-weight-bold text-heading-4 px-0">
            {{ $t("ratio_to_sales") }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="fl-ratio-earning-type-table-cell text-heading-4">L</td>
          <td class="font-weight-regular text-heading-4 text-right">
            {{ laborCost | toCurrency }}
          </td>
          <td class="font-weight-regular text-heading-4">
            {{ laborRatioToSale }}%
          </td>
        </tr>
        <tr class="font-weight-regular">
          <td class="fl-ratio-earning-type-table-cell text-heading-4">F</td>
          <td class="text-heading-4 text-right">{{ foodCost | toCurrency }}</td>
          <td class="text-heading-4">{{ foodRatioToSale }}%</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  name: "TableFLRatioDetail",
  props: {
    totalSale: {
      type: Number,
      default: 0,
      required: true
    },
    laborCost: {
      type: Number,
      default: 0,
      required: true
    },
    laborRatioToSale: {
      type: Number,
      default: 0,
      required: true
    },
    foodCost: {
      type: Number,
      default: 0,
      required: true
    },
    foodRatioToSale: {
      type: Number,
      default: 0,
      required: true
    }
  }
};
</script>

<style scoped>
.fl-ratio-earning-type-table-cell {
  font-weight: 600 !important;
}
.fl-ratio-detail-table {
  color: #545454 !important;
  background-color: unset !important;
}
</style>
