<template>
  <v-numeric
    v-model="_value"
    :precision="precision"
    v-bind="$attrs"
    :useCalculator="false"
    calcIcon=""
    :max="max"
    :min="0"
    locale="jp-JP"
  ></v-numeric>
</template>

<script>
import mixins from "@/mixins/index";
export default {
  name: "InputDecimal",
  mixins: [mixins],
  props: {
    value: {
      type: Number,
      default: 0,
      required: true
    },
    max: {
      type: Number,
      default: 10000000,
      required: false
    },
    precision: {
      type: Number,
      default: 1,
      required: false
    }
  },
  data() {
    return {
      isFocused: false
    };
  },
  computed: {
    _value: {
      get: function() {
        return this.value;
      },
      set: function(newValue) {
        this.$emit("input", newValue);
      }
    }
  },
  methods: {
    handleDelete(event) {
      if (this._value === 0) event.preventDefault();
    },
    handleZero(event) {
      if (this._value === 0) event.preventDefault();
    }
  }
};
</script>

<style scoped></style>
