<template>
  <ChartBarFoodAndLaborCosts
    :height="300"
    :chart-data="chartData"
    :label="chartLabel"
  />
</template>
<script>
import ChartBarFoodAndLaborCosts from "@/components/charts/ChartBarFoodAndLaborCosts";
export default {
  name: "FLRatioFoodLaborCostsChangeChart",
  components: { ChartBarFoodAndLaborCosts },
  props: {
    chartData: {
      type: Object,
      default: () => {},
      required: true
    },
    chartLabel: {
      type: Object,
      default: () => {},
      required: true
    }
  }
};
</script>

<style scoped></style>
