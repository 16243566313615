<template>
  <v-expansion-panels multiple accordion class="rounded-0">
    <v-expansion-panel
      v-for="(item, index) in items"
      :key="index"
      class="mt-3 fl-ratio-monthly-panel"
    >
      <v-expansion-panel-header expand-icon="$blueDownArrow" :ripple="false">
        <v-row>
          <v-col
            cols="6"
            class="font-weight-bold main--text lighten-1 text-heading-4"
          >
            {{ item.date }}
          </v-col>

          <v-col
            cols="4"
            class="font-weight-medium main--text lighten-3 text-heading-4"
          >
            FL {{ item.flRatio }}%
          </v-col>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <TableFLRatioDetail
          :total-sale="item.totalSale"
          :labor-cost="item.labor.amount"
          :labor-ratio-to-sale="item.labor.salesRatio"
          :food-cost="item.food.amount"
          :food-ratio-to-sale="item.food.salesRatio"
        >
        </TableFLRatioDetail>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import TableFLRatioDetail from "@/components/ui/TableFLRatioDetail";
export default {
  name: "CollapseMonthlyFLRatio",
  components: { TableFLRatioDetail },
  props: {
    items: {
      type: Array,
      default: () => [],
      required: true
    }
  }
};
</script>

<style scoped>
.v-expansion-panels >>> svg.v-icon__component {
  width: 12px;
  height: 12px;
}

.fl-ratio-monthly-panel {
  background-color: #f1f1f1 !important;
}

.fl-ratio-monthly-panel.v-expansion-panel:after {
  border: unset !important;
  box-shadow: unset !important;
}

.fl-ratio-monthly-panel.v-expansion-panel:before {
  box-shadow: unset !important;
}
</style>
