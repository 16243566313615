<template>
  <ChartBarStackedFLRatio
    :height="300"
    :chart-data="chartData"
    :food-threshold-text="labels.food.text"
    :food-threshold-value="labels.food.value"
    :labor-threshold-text="labels.labor.text"
    :labor-threshold-value="labels.labor.value"
  />
</template>
<script>
import ChartBarStackedFLRatio from "@/components/charts/ChartBarStackedFLRatio";
export default {
  name: "FLRatioTransitionChart",
  components: { ChartBarStackedFLRatio },
  props: {
    chartData: {
      type: Object,
      default: () => {},
      required: true
    },
    labels: {
      type: Object,
      default: () => {},
      required: true
    }
  }
};
</script>

<style scoped></style>
