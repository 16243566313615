<template>
  <CardBase
    number="2-1"
    :title="$t('fl_ratio_this_term')"
    :custom-date="date"
    modal-title="事業年度のFL比率"
    modal-component="FLRatioCumulativeTermModal"
  >
    <template v-slot:content-body>
      <v-row>
        <v-col
          cols="12"
          class="font-weight-bold main--text text-heading-4 text-center"
        >
          <div>
            {{ term }}
          </div>
          <div>
            {{ termDate }}
          </div>
        </v-col>

        <v-col cols="12" class="text-center">
          <v-container>
            <FLRatioCumulativeChart
              :chart-data="chartData"
              :fl-ratio="flRatio"
            />
          </v-container>
        </v-col>
      </v-row>
    </template>
  </CardBase>
</template>

<script>
import CardBase from "@/components/ui/CardBase";
import FLRatioCumulativeChart from "@/components/ui/FLRatioCumulativeChart";
import mixins from "@/mixins";
import { mapGetters } from "vuex";
import dayjs from "@/plugins/dayjs";

export default {
  name: "FLRatioCardCumulativeTerm",
  mixins: [mixins],
  components: { FLRatioCumulativeChart, CardBase },
  computed: {
    ...mapGetters(["getFLRatioReport"]),
    date() {
      return this.getFLRatioReport.date;
    },
    chartData() {
      return {
        labels: this.chartLabels,
        datasets: this.chartDatasets
      };
    },
    term() {
      return `${this.getFLRatioReport.year.term}期累計`;
    },
    termDate() {
      return `${dayjs(this.getFLRatioReport.year.start_date).format(
        "YYYY年M月"
      )}
      ～
      ${dayjs(this.getFLRatioReport.year.end_date).format("YYYY年M月")}`;
    },
    foodCost() {
      return this.getFLRatioReport.year.fl_cost.f_cost.percentage;
    },
    laborCost() {
      return this.getFLRatioReport.year.fl_cost.l_cost.percentage;
    },
    otherCost() {
      return this.getFLRatioReport.year.other_cost.percentage;
    },
    flRatio() {
      return this.foodCost + this.laborCost;
    },
    chartDatasets() {
      return [
        {
          label: "Data One",
          backgroundColor: ["#069821", "#F99F1C", "#C8C7B6"],
          data: [this.foodCost, this.laborCost, this.otherCost]
        }
      ];
    }
  },
  data() {
    return {
      chartLabels: ["Food", "Labor", "Others"]
    };
  }
};
</script>

<style scoped></style>
