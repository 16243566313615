<template>
  <v-list flat subheader three-line>
    <v-list-item>
      <v-row no-gutters>
        <v-col cols="12">
          <input-decimal
            v-model.number="_foodRatio"
            :label="$t('food_ratio')"
            suffix="%"
          >
          </input-decimal>
        </v-col>

        <v-col cols="12">
          <input-decimal
            v-model.number="_laborRatio"
            :label="$t('labor_ratio')"
            suffix="%"
          >
          </input-decimal>
        </v-col>

        <v-col cols="12">
          <validation-provider
            v-slot="{ errors }"
            name="fl_ratio"
            :rules="`required|min_value:0|max_fl_ratio:100`"
          >
            <input-decimal
              :value="flRatio"
              :label="$t('fl_ratio')"
              suffix="%"
              disabled
              :error-messages="errors"
              :error="errors.length !== 0"
            >
            </input-decimal>
          </validation-provider>
        </v-col>
      </v-row>
    </v-list-item>
  </v-list>
</template>

<script>
import mixins from "@/mixins/index";
import InputDecimal from "@/components/ui/InputDecimal";
export default {
  name: "FLRatioSettings",
  mixins: [mixins],
  components: { InputDecimal },
  props: {
    foodRatio: {
      type: Number,
      default: 0.0,
      required: true
    },
    laborRatio: {
      type: Number,
      default: 0.0,
      required: true
    }
  },
  computed: {
    _foodRatio: {
      get: function() {
        return this.foodRatio;
      },
      set: function(newValue) {
        return this.$emit("update:foodRatio", newValue);
      }
    },
    _laborRatio: {
      get: function() {
        return this.laborRatio;
      },
      set: function(newValue) {
        return this.$emit("update:laborRatio", newValue);
      }
    },
    maxFoodRatio() {
      return 100 - this.laborRatio;
    },
    maxLaborRatio() {
      return 100 - this.foodRatio;
    },
    flRatio() {
      return this.foodRatio + this.laborRatio;
    }
  },
  data() {
    return {};
  }
};
</script>

<style scoped></style>
