<script>
import { Bar, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  name: "ChartBarStackedFLRatio",
  extends: Bar,
  mixins: [reactiveProp],
  props: {
    foodThresholdText: {
      type: String,
      default: "",
      required: true
    },
    foodThresholdValue: {
      type: Number,
      default: 0,
      required: true
    },
    laborThresholdText: {
      type: String,
      default: "",
      required: true
    },
    laborThresholdValue: {
      type: Number,
      default: 0,
      required: true
    }
  },
  computed: {
    annotations() {
      return this.labels.map(label => {
        return {
          drawTime: "afterDraw",
          id: label.id,
          type: "line",
          mode: "horizontal",
          scaleID: "y-axis-0",
          value: label.value,
          borderColor: label.color,
          borderWidth: label.width,
          borderDash: [8, 10]
        };
      });
    },
    labelAnnotations() {
      return this.labels.map(label => {
        return {
          annotationId: label.id,
          text: label.text
        };
      });
    }
  },
  data() {
    return {
      chartOptions: {
        title: {
          display: true,
          text: ""
        },
        layout: {
          padding: {
            left: 20,
            right: 12
          }
        },
        barRoundness: 0.4,
        responsive: true,
        devicePixelRatio: 1,
        maintainAspectRatio: true,
        showTooltips: false,
        tooltips: {
          mode: "index",
          position: "top",
          caretSize: 0,
          callbacks: {
            label: function(tooltipItem, data) {
              var label = data.datasets[tooltipItem.datasetIndex].label || "";

              if (label) {
                label += ": ";
              }
              return `${label}${tooltipItem.value}%`;
            }
          }
        },
        annotation: {
          events: ["click"],
          annotations: [
            {
              drawTime: "afterDatasetsDraw",
              id: "threshold-1",
              type: "line",
              mode: "horizontal",
              scaleID: "y-axis-0",
              value: this.foodThresholdValue,
              borderColor: "#920000",
              borderWidth: 2,
              borderDash: [8, 10]
            },
            {
              drawTime: "afterDatasetsDraw",
              id: "threshold-2",
              type: "line",
              mode: "horizontal",
              scaleID: "y-axis-0",
              value: this.laborThresholdValue,
              borderColor: "#920000",
              borderWidth: 2,
              borderDash: [8, 10]
            }
          ]
        },
        legend: {
          display: false,
          position: "bottom",
          labels: {
            fontSize: 14,
            fontFamily: "Roboto"
          }
        },
        scales: {
          xAxes: [
            {
              stacked: true,
              gridLines: {
                zeroLineColor: "transparent",
                display: false,
                drawOnChartArea: false
              },
              ticks: {
                fontColor: "#444444",
                fontFamily: "Roboto",
                fontSize: 14
              }
            }
          ],
          yAxes: [
            {
              stacked: true,
              gridLines: {
                color: "transparent",
                display: true,
                drawBorder: false,
                zeroLineColor: "#ccc",
                zeroLineWidth: 2
              },
              ticks: {
                display: true,
                fontColor: "transparent",
                beginAtZero: true,
                steps: 10,
                stepValue: 5,
                max: 100
              }
            }
          ]
        },
        plugins: {
          datalabels: false,
          customAnnotationLabel: [
            {
              annotationId: "threshold-1",
              text: this.foodThresholdText
            },
            {
              annotationId: "threshold-2",
              text: this.laborThresholdText
            }
          ],
          deferred: {
            xOffset: 150,
            yOffset: "50%",
            delay: 500
          }
        }
      }
    };
  },
  mounted() {
    this.renderChart(this.chartData, this.chartOptions);
  }
};
</script>

<style scoped></style>
