import Chart from "chart.js";
import merge from "lodash/merge";

const defaultOptions = {
  customAnnotationLabel: {
    annotationId: "",
    text: "",
    font: {
      color: "#920000",
      family: "Roboto",
      size: 14,
      style: "normal"
    },
    xOffset: -10,
    yOffset: 7
  }
};
let plugin = {
  id: "customAnnotationLabel",
  afterDatasetsDraw: chart => {
    let labels = [];
    chart.options.plugins.customAnnotationLabel.forEach(item => {
      labels.push(merge({}, defaultOptions.customAnnotationLabel, item));
    });
    for (const option of labels) {
      if (chart.chart.annotation.elements[option.annotationId] !== undefined) {
        let ctx = chart.chart.ctx;
        ctx.font = Chart.helpers.fontString(
          option.font.size,
          option.font.style,
          option.font.family
        );
        ctx.textAlign = "right";
        ctx.textBaseline = "bottom";
        let line = chart.chart.annotation.elements[option.annotationId],
          x = line._model.x1 + option.xOffset,
          y = line._model.y1 + option.yOffset;
        ctx.fillStyle = option.font.color;
        ctx.fillText(option.text, x, y);
      }
    }
  }
};
Chart.plugins.register(plugin);
