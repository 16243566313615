<template>
  <v-container>
    <v-card :loading="loading">
      <v-card-title>
        {{ $t("fl_ratio_settings") }}
      </v-card-title>

      <validation-observer ref="observer">
        <form @submit.prevent="update">
          <FLRatioSettings
            :food-ratio.sync="foodRatio"
            :labor-ratio.sync="laborRatio"
          />
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn type="submit" :loading="loading" color="primary" block>
              {{ $t("update") }}
            </v-btn>
          </v-card-actions>
        </form>
      </validation-observer>
    </v-card>
  </v-container>
</template>

<script>
import FLRatioSettings from "@/components/layout/user/settings/FLRatioSettings";
import { mapGetters } from "vuex";

export default {
  name: "FLRatio",
  components: { FLRatioSettings },
  created() {
    this.loading = true;
    this.$store
      .dispatch("FL_RATIO_SETTINGS_GET")
      .then(() => {
        this.foodRatio = this.getFLRatioFoodRatio;
        this.laborRatio = this.getFLRatioLaborRatio;
      })
      .finally(() => (this.loading = false));
  },
  computed: {
    ...mapGetters(["getFLRatioFoodRatio", "getFLRatioLaborRatio"])
  },
  data() {
    return {
      loading: false,
      foodRatio: 0.0,
      laborRatio: 0.0
    };
  },
  methods: {
    update() {
      this.$refs.observer.validate().then(success => {
        if (!success) {
          return;
        }
        this.loading = true;
        this.$store
          .dispatch("FL_RATIO_SETTINGS_UPDATE", {
            f_ratio: this.foodRatio,
            l_ratio: this.laborRatio
          })
          .finally(() => {
            this.loading = false;
          });
      });
    }
  }
};
</script>

<style scoped></style>
