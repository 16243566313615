<template>
  <CardBase
    number="2-2"
    :title="$t('fl_ratio_transition')"
    :custom-date="date"
    modal-title="FL比率と独自FL目標"
    modal-component="FLRatioTransitionModal"
  >
    <template v-slot:content-body>
      <v-row>
        <v-col
          cols="12"
          class="font-weight-bold main--text text-heading-3 text-center"
        >
          {{ $t("fl_ratio_transition_changes_to_sales") }}
        </v-col>

        <v-col cols="12" class="text-center">
          <FLRatioTransitionChart
            :chart-data="chartDataTransition"
            :labels="transitionChartLabels"
          />
        </v-col>
        <v-col
          cols="12"
          class="font-weight-bold main--text text-heading-3 text-center"
        >
          {{ $t("fl_ratio_transition_changes_to_food_cost") }}
        </v-col>

        <v-col cols="12" class="text-center">
          <FLRatioFoodLaborCostsChangeChart
            :chart-data="chartDataFoodCosts"
            :chart-label="foodChartLabels"
          />
        </v-col>
        <v-col
          cols="12"
          class="font-weight-bold main--text text-heading-3 text-center"
        >
          {{ $t("fl_ratio_transition_changes_to_labor_cost") }}
        </v-col>

        <v-col cols="12" class="text-center">
          <FLRatioFoodLaborCostsChangeChart
            :chart-data="chartDataLaborCosts"
            :chart-label="laborChartLabels"
          />
        </v-col>
      </v-row>
    </template>
  </CardBase>
</template>

<script>
import CardBase from "@/components/ui/CardBase";
import FLRatioTransitionChart from "@/components/ui/FLRatioTransitionChart";
import FLRatioFoodLaborCostsChangeChart from "@/components/ui/FLRatioFoodLaborCostsChangeChart";
import { mapGetters } from "vuex";

export default {
  name: "FLRatioTransition",
  components: {
    FLRatioFoodLaborCostsChangeChart,
    FLRatioTransitionChart,
    CardBase
  },
  created() {
    this.fillData();
  },
  computed: {
    ...mapGetters(["getFLRatioReport"]),

    date() {
      return this.getFLRatioReport.date;
    },

    foodChartLabels() {
      return {
        id: "threshold",
        value: this.getFLRatioReport.year.fl_goal.f_goal.percentage,
        color: "#920000",
        width: 2,
        text: `F${this.getFLRatioReport.year.fl_goal.f_goal.percentage}%`
      };
    },

    laborChartLabels() {
      return {
        id: "threshold",
        value: this.getFLRatioReport.year.fl_goal.l_goal.percentage,
        color: "#920000",
        width: 2,
        text: `L${this.getFLRatioReport.year.fl_goal.l_goal.percentage}%`
      };
    },

    transitionChartLabels() {
      return {
        labor: {
          text: `F${this.getFLRatioReport.year.fl_goal.f_goal.percentage}%`,
          value: this.getFLRatioReport.year.fl_goal.f_goal.percentage
        },
        food: {
          text: `FL${this.getFLRatioReport.year.fl_goal.total.percentage}%`,
          value: this.getFLRatioReport.year.fl_goal.total.percentage
        }
      };
    }
  },
  methods: {
    fillData() {
      let foodTransitionData = [];
      let laborTransitionData = [];
      let otherTransitionData = [];
      let foodCostData = [];
      let laborCostData = [];
      let labels = [];

      for (const item of this.getFLRatioReport.year.monthly_costs) {
        foodTransitionData.push(item.fl_cost.f_cost.percentage);
        laborTransitionData.push(item.fl_cost.l_cost.percentage);
        otherTransitionData.push(item.other_cost.percentage);
        foodCostData.push(item.fl_cost.f_cost.percentage);
        laborCostData.push(item.fl_cost.l_cost.percentage);
        labels.push(item.month.name);
      }

      this.chartDataTransition.datasets[0].data = foodTransitionData;
      this.chartDataTransition.datasets[1].data = laborTransitionData;
      this.chartDataTransition.datasets[2].data = otherTransitionData;
      this.chartDataFoodCosts.datasets[0].data = foodCostData;
      this.chartDataLaborCosts.datasets[0].data = laborCostData;

      this.chartDataTransition.labels = labels;
      this.chartDataLaborCosts.labels = labels;
      this.chartDataFoodCosts.labels = labels;
    }
  },
  data() {
    return {
      chartDataTransition: {
        datasets: [
          {
            label: "Food",
            backgroundColor: "#069821",
            data: []
          },
          {
            label: "Labor",
            backgroundColor: "#F99F1C",
            data: []
          },
          {
            label: "Others",
            backgroundColor: "#C8C7B6",
            data: []
          }
        ],
        labels: []
      },
      chartDataFoodCosts: {
        datasets: [
          {
            label: "Food costs",
            backgroundColor: "#069821",
            data: []
          }
        ],
        labels: []
      },
      chartDataLaborCosts: {
        datasets: [
          {
            label: "Food costs",
            backgroundColor: "#F99F1C",
            data: []
          }
        ],
        labels: []
      }
    };
  }
};
</script>

<style scoped></style>
