<template>
  <CardBase
    number="2-3"
    :title="$t('fl_ratio_details')"
    :custom-date="date"
    modal-title="FL比率の詳細"
    modal-component="FLRatioDetailsModal"
  >
    <template v-slot:content-body>
      <v-row>
        <v-col cols="12">
          <v-card class="card-fl-ratio-details rounded-0" elevation="0">
            <v-card-text>
              <v-row class="text-center">
                <v-col cols="12">
                  <div
                    class="card-fl-ratio-header font-weight-medium text-heading-5"
                  >
                    {{
                      $t("fl_ratio_details_cumulative", {
                        term: getFLRatioReport.year.term
                      })
                    }}
                  </div>
                  <div
                    class="card-fl-ratio-sub-header font-weight-medium text-heading-3"
                  >
                    {{ termDate }}
                  </div>
                </v-col>

                <v-col
                  cols="auto"
                  class="ml-auto font-weight-medium text-heading-3"
                >
                  {{ $t("fl_ratio") }}
                </v-col>

                <v-col
                  cols="auto"
                  class="mr-auto card-fl-ratio-percentage font-weight-medium main--text lighten-1"
                >
                  {{ getFLRatioReport.year.fl_cost.total.percentage }}%
                </v-col>

                <v-col cols="12">
                  <TableFLRatioDetail
                    :total-sale="currentTermFlRatio.totalSale"
                    :labor-cost="currentTermFlRatio.labor.amount"
                    :labor-ratio-to-sale="currentTermFlRatio.labor.salesRatio"
                    :food-cost="currentTermFlRatio.food.amount"
                    :food-ratio-to-sale="currentTermFlRatio.food.salesRatio"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" class="text-center">
          <div class="font-weight-medium main--text lighten-2 text-heading-5">
            {{
              $t("fl_ratio_details_monthly", {
                term: getFLRatioReport.year.term
              })
            }}
          </div>
        </v-col>

        <v-col cols="12">
          <CollapseMonthlyFLRatio :items="monthlyFlRatios" />
        </v-col>

        <v-col cols="12">
          <div
            class="font-weight-regular main--text lighten-3 text-heading-2 text-center"
          >
            {{ $t("fl_ratio_details_check_message") }}
          </div>
        </v-col>
      </v-row>
    </template>
  </CardBase>
</template>

<script>
import CardBase from "@/components/ui/CardBase";
import TableFLRatioDetail from "@/components/ui/TableFLRatioDetail";
import CollapseMonthlyFLRatio from "@/components/ui/CollapseMonthlyFLRatio";
import dayjs from "@/plugins/dayjs";
import { mapGetters } from "vuex";
import mixins from "@/mixins";

export default {
  name: "FLRatioDetails",
  mixins: [mixins],
  components: { CollapseMonthlyFLRatio, TableFLRatioDetail, CardBase },
  created() {
    this.fillData();
  },
  computed: {
    ...mapGetters(["getFLRatioReport"]),

    date() {
      return this.getFLRatioReport.date;
    },

    currentTermFlRatio() {
      return {
        totalSale: this.getFLRatioReport.year.fl_cost.total.amount,
        labor: {
          amount: this.getFLRatioReport.year.fl_cost.l_cost.amount,
          salesRatio: this.getFLRatioReport.year.fl_cost.l_cost.percentage
        },
        food: {
          amount: this.getFLRatioReport.year.fl_cost.f_cost.amount,
          salesRatio: this.getFLRatioReport.year.fl_cost.f_cost.percentage
        }
      };
    },

    termDate() {
      return `${dayjs(this.getFLRatioReport.year.start_date).format(
        "YYYY年M月"
      )}
      ～
      ${dayjs(this.getFLRatioReport.year.end_date).format("YYYY年M月")}`;
    }
  },
  methods: {
    fillData() {
      let monthly = [];
      for (const item of this.getFLRatioReport.year.monthly_costs) {
        monthly.push({
          date: item.month.long_name,
          flRatio: item.fl_cost.total.percentage,
          totalSale: item.fl_cost.total.amount,
          labor: {
            amount: item.fl_cost.l_cost.amount,
            salesRatio: item.fl_cost.l_cost.percentage
          },
          food: {
            amount: item.fl_cost.f_cost.amount,
            salesRatio: item.fl_cost.f_cost.percentage
          }
        });
      }

      this.monthlyFlRatios = monthly;
    }
  },
  data() {
    return {
      monthlyFlRatios: []
    };
  }
};
</script>

<style scoped>
.card-fl-ratio-percentage {
  font-size: 30px !important;
}

.card-fl-ratio-header {
  color: #6b5c07 !important;
}

.card-fl-ratio-sub-header {
  color: #bcaa47 !important;
}

.card-fl-ratio-details {
  background-color: #fffceb !important;
}
</style>
