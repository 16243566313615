<script>
import annotations from "chartjs-plugin-annotation";
import { mixins } from "vue-chartjs";
import RoundedBar from "@/plugins/charts/RoundedCornerBar";
import "@/plugins/charts/AnnotationLabelPlugin";
import "@/plugins/charts/TooltipPositionTop";
const { reactiveProp } = mixins;

export default {
  name: "ChartBarFoodAndLaborCosts",
  extends: RoundedBar,
  mixins: [reactiveProp],
  props: {
    label: {
      type: Object,
      default: () => {},
      required: true
    }
  },
  computed: {
    annotations() {
      return this.label.map(label => {
        return {
          drawTime: "afterDraw",
          id: label.id,
          type: "line",
          mode: "horizontal",
          scaleID: "y-axis-0",
          value: label.value,
          borderColor: label.color,
          borderWidth: label.width,
          borderDash: [8, 10]
        };
      });
    },
    labelAnnotations() {
      return this.label.map(label => {
        return {
          annotationId: label.id,
          text: label.text
        };
      });
    }
  },
  data() {
    return {
      chartOptions: {
        title: {
          display: true,
          text: ""
        },
        layout: {
          padding: {
            left: 20,
            right: 12
          }
        },
        barRoundness: 0.2,
        responsive: true,
        devicePixelRatio: 1,
        maintainAspectRatio: true,
        showTooltips: false,
        tooltips: {
          position: "top",
          displayColors: false,
          caretSize: 0,
          callbacks: {
            label: function(tooltipItem) {
              return `${tooltipItem.value}%`;
            }
          }
        },
        annotation: {
          events: ["click"],
          annotations: [
            {
              drawTime: "afterDatasetsDraw",
              id: this.label.id,
              type: "line",
              mode: "horizontal",
              scaleID: "y-axis-0",
              value: this.label.value,
              borderColor: this.label.color,
              borderWidth: this.label.width,
              borderDash: [8, 10]
            }
          ]
        },
        legend: {
          display: false,
          position: "bottom",
          labels: {
            fontSize: 14,
            fontFamily: "Roboto"
          }
        },
        scales: {
          xAxes: [
            {
              stacked: true,
              gridLines: {
                zeroLineColor: "transparent",
                display: false,
                drawOnChartArea: false
              },
              ticks: {
                fontColor: "#444444",
                fontFamily: "Roboto",
                fontSize: 14
              }
            }
          ],
          yAxes: [
            {
              gridLines: {
                color: "transparent",
                display: true,
                drawBorder: false,
                zeroLineColor: "#ccc",
                zeroLineWidth: 2
              },
              ticks: {
                display: true,
                fontColor: "transparent",
                beginAtZero: true,
                steps: 10,
                stepValue: 5,
                max: 100
              }
            }
          ]
        },
        plugins: {
          datalabels: false,
          customAnnotationLabel: [
            {
              annotationId: this.label.id,
              text: this.label.text
            }
          ],
          deferred: {
            xOffset: 150,
            yOffset: "50%",
            delay: 500
          }
        }
      }
    };
  },
  mounted() {
    this.addPlugin(annotations);
    this.renderChart(this.chartData, this.chartOptions);
  }
};
</script>

<style scoped></style>
