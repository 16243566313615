<template>
  <v-container v-if="!loading" fluid>
    <v-row>
      <v-col cols="12">
        <FLRatioCardCumulativeTerm />
      </v-col>

      <v-col cols="12">
        <FLRatioTransition />
      </v-col>

      <v-col cols="12">
        <FLRatioDetails />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FLRatioCardCumulativeTerm from "@/components/layout/fl-ratio/FLRatioCardCumulativeTerm";
import FLRatioTransition from "@/components/layout/fl-ratio/FLRatioTransition";
import FLRatioDetails from "@/components/layout/fl-ratio/FLRatioDetails";
export default {
  name: "FLRatio",
  components: { FLRatioDetails, FLRatioTransition, FLRatioCardCumulativeTerm },
  created() {
    this.loading = true;
    this.$store
      .dispatch("FL_RATIO_REPORT_GET")
      .then(() => {})
      .finally(() => {
        this.loading = false;
      });
  },
  data() {
    return {
      loading: false
    };
  }
};
</script>

<style scoped></style>
